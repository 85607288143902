require('./bootstrap');


$(document).ready(function () {

    let defaultTime = 30000;
    let statusTime = 2000;
    let updateTime = statusTime;
    let timer = null;

    function start() {
        if ($('.table').data('page') <= 1) {
            $('.prev').hide();
        }
        $('.next').hide();
    }

    start();

    function setLocation(curLoc) {
        try {
            history.pushState(null, null, curLoc);
            return;
        } catch (e) {
        }
        location.hash = '#' + curLoc;
    }

    $('.panel').on('click', '.next', function () {
        let id = $('.table').data('page') + 1;
        setLocation('?page=' + id);
        $('.table').data('page', id);
        updateTimer();
        $('.prev').show();
    });

    $('.panel').on('click', '.prev', function () {
        let id = $('.table').data('page') - 1;
        if (id > 0) {
            setLocation('?page=' + id);
            $('.table').data('page', id);
            updateTimer();
        }
        if (id <= 1) {
            $(this).hide();
        }
    });

    function updateTimer() {
        updateColls(function () {
            if (timer) window.clearTimeout(timer);
            timer = window.setTimeout(updateTimer, updateTime)
        });
    }

    function updateColls(func) {
        $('.next').hide();
        $.get({
            url: '/api/v1/files',
            type: 'GET',
            data: {page: $('.table').data('page')},
            dataType: 'json',
            success: function (msg) {
                $(".table__colls").empty();
                let colls = '';
                let timerCol = false;
                $.each(msg.Files, function (i, val) {
                    if (i < 20) {
                        var formattedDate = new Date(val.DateCreate);
                        var d = formattedDate.getDate();
                        var m = formattedDate.getMonth();
                        m += 1;
                        var y = formattedDate.getFullYear();
                        if (m < 10) m = '0' + m;
                        let DateCreate = d + "/" + m + "/" + y;
                        let fileIn = '<a target="_blank" href="/files/' + val.FileWorkId + '/import" class="download-btn"></a>';
                        let fileOut = (val.CountRow == val.CountAccept + val.CountFailed) ? '<a target="_blank" class="download-btn" href="/files/' + val.FileWorkId + '/export"></a>' : '-';

                        let errors = '<a class="open-btn" href="#" data-error="' + val.ErrorDescription + '">открыть</a>';
                        if (!val.ErrorDescription) {
                            errors = '-';
                        }

                        let procent = 100;
                        if (val.StatusId == 1) timerCol = true;
                        if (val.StatusId == 2) {
                            timerCol = true;
                            if (val.CountRow > 0) {
                                procent = 100 / val.CountRow * (val.CountAccept + val.CountFailed);
                            } else {
                                procent = 0;
                            }
                        }

                        if (updateTime != statusTime && (val.StatusId == 1 || val.StatusId == 2) && timerCol) {
                            updateTime = statusTime;
                        } else if (updateTime == statusTime && !(val.StatusId == 1 || val.StatusId == 2) && !timerCol) {
                            updateTime = defaultTime;
                        }

                        colls +=
                            '<div class="table__coll">' +
                            '<div class="table__row" style="width: 180px;">' + val.FileName + '</div>' +
                            '<div class="table__row" style="width: 90px; text-align: right;">' + Number(val.CountAccept) + '</div>' +
                            '<div class="table__row" style="width: 105px; text-align: right;">' + Number(val.CountFailed) + '</div>' +
                            '<div class="table__row table__row_status status' + val.StatusId +
                            '" style="width: 130px; text-align: center;"><div style="width: ' + procent + '%"></div><span>' + val.StatusName + '</span></div>' +
                            '<div class="table__row" style="width: 110px; text-align: center;">' + fileIn + '</div>' +
                            '<div class="table__row" style="width: 140px; text-align: center;">' + fileOut + '</div>' +
                            '<div class="table__row" style="width: 110px; text-align: center;">' + DateCreate + '</div>' +
                            '<div class="table__row" style="width: 95px; text-align: center;">' + errors + '</div>' +
                            '</div>';
                    } else {
                        $('.next').show();
                    }
                });
                $('.table__colls').append(colls);
                func();
            },
            error: function (jqXHR, status, errorThrown) {
                func();
            }
        });
    }

    $('.table__colls').on('click', '.open-btn', function () {
        $('#error-name').text($(this).data('error'));
        $('#errors-form').toggleClass('popup_show');
    });

    $('.panel').on('click', '.table_refresh', function () {
        updateTimer();
    });

    $('.popup .popup__btn_close').click(function () {
        $(this).closest('.popup').toggleClass('popup_show');
    });

    $('.popup .popup__close').click(function () {
        $(this).closest('.popup').toggleClass('popup_show');
    });

    if ($('#panel_index').length > 0) {

        updateTimer();

        $('#file-upload-btn').click(function () {
            $('#file-upload-form').toggleClass('popup_show');
            $('.popup__body_error').hide();
        });

        $('#file-upload-form-file').change(function () {
            $('.popup__body_error').hide();
        });

        $('#file-upload-form-submit').click(function () {
            let file = $('#file-upload-form-file').prop('files')[0];
            if (typeof file == 'undefined') {
                let errorBlock = $('.popup__body_error');
                errorBlock.text('Выберите файл!');
                errorBlock.show();
                return;
            }

            let data = new FormData();

            data.append('file', file);
            data.append('_token', $('meta[name="csrf-token"]').attr('content'));

            $.ajax({
                url: '/api/v1/file',
                type: 'POST',
                data: data,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                success: function (respond, status, jqXHR) {
                    $("#file-upload-form-file").val(null);
                    $('#file-upload-form').toggleClass('popup_show');
                    updateTimer();
                },
                error: function (jqXHR, status, errorThrown) {
                    let errorBlock = $('.popup__body_error');
                    let errorStatus = jqXHR.status;

                    errorBlock.text('Ошибка загрузки файла!');
                    if (errorStatus === 422) {
                        errorBlock.text('Ошибка валидации файла!');

                        if (jqXHR.responseJSON.errors.file[0] === 'The file must be a file of type: csv, xls, xlsx.')
                            errorBlock.text('Файл имеет расширение отличное от csv, xls, xlsx!');
                    } else if (errorStatus === 413) {
                        errorBlock.text('Файл слишком большой!');
                    } else if (errorStatus === 500) {
                        errorBlock.text('Сервис недоступен!');
                    } else if (errorStatus === 400) {
                        errorBlock.text('Ошибка сервиса загрузки!');
                    }

                    errorBlock.show();
                }
            });
        });
    }
});
